<template>
    <div class="launch-container">
        <div class="form-overflow">
            <div class="launch-title">发起审批</div>
            <div class="grey-line-1px"></div>
            <el-form ref="launchTaskForm" :model="launchTaskForm" :rules="launchTaskRules" label-width="160px" class="demo-ruleForm">
                <div class="taskForm-LeftRight">
                    <el-form-item label="类型名称：" prop="workOrderId">
                        <el-select :disabled="id ? true : false" placeholder="请选择类型" class="common-screen-input_100" @change="(val) => { changeTypeFun(val) }" v-model="launchTaskForm.workOrderId" filterable>
                            <el-option v-for="(item, index) in taskTypeSelect" :key="index" :label="item.workTypeName" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="审批工单名称：" prop="taskName">
                        <el-input class="common-screen-input_100" placeholder="任务工单名称" v-model="launchTaskForm.taskName"></el-input>
                    </el-form-item>
                    <el-form-item label="优先级：">
                        <el-select placeholder="请选择优先级" class="common-screen-input_100" v-model="launchTaskForm.priority" filterable>
                            <el-option label="低" :value="1" ></el-option>
                            <el-option label="中" :value="2" ></el-option>
                            <el-option label="高" :value="3" ></el-option>
                            <el-option label="紧急" :value="4" ></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="计划开始时间：" prop="planStartTime">
                        <el-date-picker class="common-screen-input_100" :picker-options="startPickerOptions" @change="(val) => { dateTime(val, 'start') }" value-format="yyyy-MM-dd HH:mm:ss" v-model="launchTaskForm.planStartTime" type="datetime" placeholder="选择日期时间"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="计划完成时间：" prop="planEndTime">
                        <el-date-picker class="common-screen-input_100" :picker-options="endPickerOptions" @change="(val) => { dateTime(val, 'end') }" value-format="yyyy-MM-dd HH:mm:ss" v-model="launchTaskForm.planEndTime" type="datetime" placeholder="选择日期时间"></el-date-picker>
                    </el-form-item> 
                </div>
                <div class="taskForm-LeftRight">
                    <el-form-item label="描述">
                        <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="launchTaskForm.remark" maxlength="500" show-word-limit></el-input>
                    </el-form-item> 
                    <el-form-item label="附件：" >
                        <div class="common-uploadBnList-container">
                            <div class="fileBtn-container">
                                <div class="fileBtn"> 上传附件 <input class="file-input" type="file" @input="(e) => {uploadFile(e)}"></div>
                                上传文件及图片，大小不超过50MB
                            </div>
                            <br/>
                            <div class="fileList-container">
                                <div v-for="(item, index) in fileList" :key="index" class="fileList-item">
                                    <div class="item-name">
                                        <common-picture :fileUrl="item.filedUrl" :fileType="item.filedType" :fileName="item.filedName"></common-picture>
                                    </div>
                                    <div @click="() => {deleteFlie(index)}" class="deleteBtn">删除</div>
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                </div>
            </el-form>
            <div class="workOrder-title">工单流程</div>
            <div v-if="!launchTaskForm.workOrderId" style="margin: 16px 0 32px 32px;color: #696969">请选择工单类型模板</div>
            <div v-else class="workOrder-process-container">
                <el-timeline>
                    <el-timeline-item>
                        <template slot="dot">
                            <div class="timelineItem-dot">1</div>
                        </template>
                        <div class="process-title">创建工单</div>
                    </el-timeline-item>
                    <el-timeline-item v-for="(item, index) in launchTaskForm.flowList" :key="index">
                        <template slot="dot">
                            <div class="timelineItem-dot">{{index + 2}}</div>
                        </template>
                        <div>
                            <!-- <div class="process-input-container">
                                <el-input v-model="item.taskFlowName" placeholder="任务名称"><template slot="prepend">任务名称</template></el-input>
                            </div> -->
                            <!-- <div class="process-textarea-container">
                                <div class="textarea-title">任务描述</div>
                                <el-input type="textarea" :rows="6" placeholder="任务描述" v-model="item.taskFlowDescribe" maxlength="500" show-word-limit></el-input>
                            </div> -->
                            <!-- <div class="common-uploadBnList-container">
                                <div class="fileBtn-container">
                                    <div class="fileBtn"> 上传附件 <input class="file-input" type="file" @input="(e) => {uploadFileItem(e, index)}"></div>
                                    上传文件及图片，大小不超过50MB
                                </div>
                                <br/>
                                <div class="fileList-container">
                                    <div v-for="(itemTwo, indexTwo) in item.fileList" :key="indexTwo" class="fileList-item">
                                        <div class="item-name">
                                            <common-picture :fileUrl="itemTwo.filedUrl" :fileType="itemTwo.filedType" :fileName="itemTwo.filedName"></common-picture>
                                        </div>
                                        <div @click="() => {deleteFlieItem(index, indexTwo)}" class="deleteBtn">删除</div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="common-multiple-container" :style="item.status == 0 ? '':'background: #efefef'">
                                <div class="label">审批人</div>
                                <div class="content">
                                    <div v-for="(itemTwo, indexTwo) in item.noticeList" :key="indexTwo" class="multiple-item">{{itemTwo.name}}</div>
                                </div>
                                <div class="multiple-btn" v-if="item.status == 0 && !item.dealId" @click="() => { personShow(item.dealRole, index) }">选择人员</div>
                                <div class="multiple-btn" v-else style="color: #ccc">选择人员</div>
                            </div>
                            <br/>
                        </div>
                    </el-timeline-item>
                    <el-timeline-item>
                        <template slot="dot">
                            <div class="timelineItem-dot">{{launchTaskForm.flowList.length + 1}}</div>
                        </template>
                        <div class="common-multiple-container">
                            <div class="label">抄送人</div>
                            <div class="content">
                                <div v-for="(item, index) in launchTaskForm.sendList" :key="index" class="multiple-item">{{item.name}}</div>
                            </div>
                            <div class="multiple-btn" v-if="!launchTaskForm.sendId" @click="() => { personShow(launchTaskForm.sendRole, 'sendRole') }">选择人员</div>
                            <div class="multiple-btn" v-else style="color: #ccc">选择人员</div>
                        </div>
                    </el-timeline-item>
                    <!-- <el-timeline-item>
                        <template slot="dot">
                            <div class="timelineItem-dot">{{launchTaskForm.flowList.length + 2}}</div>
                        </template>
                        <div class="common-multiple-container">
                            <div class="label">负责人</div>
                            <div class="content">
                                <div v-for="(item, index) in launchTaskForm.chargeList" :key="index" class="multiple-item">{{item.name}}</div>
                            </div>
                            <div class="multiple-btn" @click="() => { personShow(launchTaskForm.chargeRole, 'chargeRole') }">选择人员</div>
                        </div>
                    </el-timeline-item> -->
                    <el-timeline-item>
                        <template slot="dot">
                            <div class="timelineItem-dot">{{launchTaskForm.flowList.length + 3}}</div>
                        </template>
                        <div class="process-title">关闭工单</div>
                    </el-timeline-item>
                </el-timeline>
            </div>
        </div>
        <div class="grey-line"></div>
        <div class="footer-container">
            <div class="btn-content">
                <el-button class="common-screen-btn" @click="() => { $router.go(-1) }">返回</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => { launchTask('launchTaskForm') }">{{!$route.query.id ? '立即指派' : '保存'}}</el-button>
            </div>
        </div>
        <el-dialog v-dialogDrag title="选择人员" :append-to-body="true" :visible.sync="personVisible" width="630px" :before-close="() => { personVisible = false }" :close-on-click-modal="false">
            <div class="common-chooseItem-container">
                <div v-for="(item, index) in checkedList" :key="index" class="common-choose-item">
                    <span>{{item.name}}</span><i @click="() => { deletePerson(item) }" class="el-icon-circle-close"></i>
                </div>
            </div>
            <br/><br/>
            <div class="common-person-container">
                <div class="person-title">
                    <div class="left-text">组织成员</div>
                    <div class="right-seach">
                        <el-input class="seach-input" placeholder="请输入内容" prefix-icon="el-icon-search" @change="(val) => seachFun(val)" v-model="seachInfo"></el-input>
                    </div>
                </div>
                <div class="person-content">
                    <div class="content-left">
                        <el-tree :data="roleData" :props="defaultProps" @node-click="(val) => { departTreeFun(val) }"></el-tree>
                    </div>
                    <div class="content-right">
                        <div v-for="( item, index ) in personData" :key="index">
                            <el-checkbox v-model="item.checked" @change="(val) => { changePerson(val, item) }">{{item.nickName}}</el-checkbox>
                        </div>
                        
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="() => { personVisible = false }">取 消</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => { noticeFun() }">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { Config, DateTimeTransform } from "../../utils/index.js"
import { upload } from "../../service/upload.js"
import { workOrderType, workOrderTemplate, launchTask, updateWork, getTemplateRole, taskDetail } from "../../service/workOrder.js"
import { getRoleList } from "../../service/manage.js"
import { personList, commonAllRole } from "../../service/common.js"
import CommonPicture from "../../components/common/commonPicture.vue"
import { mapState } from "vuex";

export default {
    components: { CommonPicture },
    computed: {
        ...mapState(["getUserInfo","comId"])
    },
    data() {
        return {
            id: this.$route.query.id,
            // companyId: this.$sto.get(Config.constants.userInfo).company_id,
            launchTaskForm: {
                flowList: [{noticeList: []}],
                chargeList: [],
                sendList: []
            },
            launchTaskRules: {
                planStartTime: [{ required: true, message: "请选择结束时间", trigger: "change" }],
                planEndTime: [{ required: true, message: "请选择结束时间", trigger: "change" }],
                workOrderId: [{ required: true, message: "请选择任务类型", trigger: "change" }],
                taskName: [{ required: true, message: "请填写任务名称", trigger: "change" }],
            },
            taskTypeSelect: [],
            fileList: [],
            personVisible: false,
            checkedList: [],
            noticeList: [],
            defaultProps: {
                children: 'child',
                label: 'name'
            },
            personData: [],
            seachInfo: '',
            roleData: [],
            editIndex: 0, // 当前选择处理人的下标
             startPickerOptions: { // 禁用时间范围
            },
            endPickerOptions: { // 禁用时间范围
            },
        }
    },
    mounted() {
        // this.workOrderType()
    },
    created () {
        if (this.$route.query.id) {
            this.taskDetail()
        }
        this.getComId()
    },
    methods: {
        // 获取comID&&请求列表
        getComId() {
          setTimeout(()=>{
            if(this.comId){
            this.workOrderType()
            }
          },400)
        },
        dateTime (val, type) {
            if (type == 'start') {
                this.endPickerOptions = {
                    disabledDate: (time) => {
                        return time.getTime() < new Date(val).getTime();
                    },
                }
            } else {
                this.startPickerOptions = {
                    disabledDate: (time) => {
                        return time.getTime() > new Date(val).getTime();
                    },
                }
            }
        },
        async taskDetail() {
            let { data } = await taskDetail({taskId: this.$route.query.id})
            // if (data && data.flowList && data.flowList.length) {
            //     data.flowList.map((item) => {
            //         item.fileList = []
            //         item.noticeList = []
            //     })
            // }
            data.planStartTime = DateTimeTransform(data.planStartTime)
            data.planEndTime = DateTimeTransform(data.planEndTime)
            if (data.remarkFileName && data.remarkFileUrl) {
                let fileList = []
                let remarkFileName = data.remarkFileName.split(',')
                let remarkFileUrl = data.remarkFileUrl.split(',')
                remarkFileName.map((item, index) => {
                    fileList.push({ filedName: item, filedUrl: remarkFileUrl[index], filedType: Config.fileType.imgType.includes(item.split('.')[item.split('.').length - 1]) ? 'imgText' : 'file'})
                })
                this.fileList = fileList
            }
            if (data.sendId && data.sendName) {
                let sendList = []
                let sendName = data.sendName.split(',')
                let sendId = data.sendId.split(',')
                sendName.map((item, index) => {
                    sendList.push({ name: item, id: sendId[index] })
                })
                data.sendList = sendList
            }
            if (data.chargeId && data.chargeName) {
                data.chargeList = [{name: data.chargeName, id: data.chargeId}]
            }
            if (data.flowList) {
                data.flowList.map((item) => {
                    if (item.dealId && item.dealName) {
                        item.noticeList = [{name: item.dealName, id: item.dealId}]
                    }
                    item.fileList = []
                    if (item.fileName && item.fileUrl) {
                        let fileName = item.fileName.split(',')
                        let fileUrl = item.fileUrl.split(',')
                        fileName.map((itemTwo, indexTwo) => {
                            item.fileList.push({ filedName: itemTwo, filedUrl: fileUrl[indexTwo], filedType: Config.fileType.imgType.includes(itemTwo.split('.')[itemTwo.split('.').length - 1]) ? 'imgText' : 'file'})
                        })
                    }
                })
            }
            this.launchTaskForm = data
        },
        noticeFun() {
            let index = this.editIndex
            let launchTaskForm = JSON.parse(JSON.stringify(this.launchTaskForm))
            if ( index == 'chargeRole' ) {
                launchTaskForm.chargeList =  [...this.checkedList]
            } else if ( index == 'sendRole' ) {
                launchTaskForm.sendList =  [...this.checkedList]
            } else {
                launchTaskForm.flowList[index].noticeList =  [...this.checkedList]
            }
            this.launchTaskForm = launchTaskForm
            this.personVisible = false
        },
        launchTask (formName) { // 发起任务-提交
            this.$refs[formName].validate(async (valid) => {
                if (valid) { // 发送请求
                    let launchTaskForm = JSON.parse(JSON.stringify(this.launchTaskForm))
                    let fileList = [...this.fileList]
                    if (fileList) {  // 描述文件
                        let remarkFileName = []
                        let remarkFileUrl = []
                        fileList.map((item) => {
                            remarkFileName.push(item.filedName)
                            remarkFileUrl.push(item.filedUrl)
                        })
                        launchTaskForm.remarkFileName = remarkFileName.join(',')
                        launchTaskForm.remarkFileUrl = remarkFileUrl.join(',')
                    }

                    if (launchTaskForm.sendList && launchTaskForm.sendList.length) {  // 抄送人
                        let sendList = []
                        launchTaskForm.sendList.map((item) => {
                            sendList.push(item.id)
                        })
                        launchTaskForm.sendId = sendList.join(',')
                    }
                    //  else { return this.$message.error("请选择抄送人") }

                    // if (launchTaskForm.chargeList && launchTaskForm.chargeList.length) {  // 负责人
                    //     let chargeList = []
                    //     launchTaskForm.chargeList.map((item) => {
                    //         chargeList.push(item.id)
                    //     })
                    //     launchTaskForm.chargeId = chargeList.join(',')
                    // } else { return this.$message.error("请选择负责人") }

                    if (launchTaskForm.flowList) {  // 任务步骤
                        let isNull = false
                        launchTaskForm.flowList.map((item) => {
                            // if ( !item.taskFlowName && item.taskFlowName !== 0) {  // 任务步骤-名称
                            //     isNull = true
                            // }
                            if ( item.noticeList && item.noticeList.length ) { // 任务步骤-处理人
                                let dealList = []
                                item.noticeList.map((itemTwo) => {
                                    dealList.push(itemTwo.id)
                                })
                                item.dealId = dealList.join(",")
                                delete item.noticeList
                            } else {
                                isNull = true
                            }
                            if ( item.fileList && item.fileList.length ) { // 任务步骤-文件
                                let fileName = []
                                let fileUrl = []
                                item.fileList.map((itemTwo) => {
                                    fileName.push(itemTwo.filedName)
                                    fileUrl.push(itemTwo.filedUrl)
                                })
                                item.fileName = fileName.join(",")
                                item.fileUrl = fileUrl.join(",")
                                delete item.fileList
                            } else {
                                item.fileName = ''
                                item.fileUrl = ''
                                delete item.fileList
                            }
                        })
                        if (isNull) {
                            return this.$message.error("审批人不能为空")
                        }
                    }
                    let code
                    if (this.$route.query.id) {
                        code = (await updateWork(launchTaskForm)).code
                    } else {
                        code = (await launchTask(launchTaskForm)).code
                    }
                    if ( code == 200 ) {
                        this.$message.success("指派成功")
                        this.$router.push('/work-order/approve')
                    }
                } else {
                    this.$message.error("请填写完整的信息");
                }
            });
        },
        async getTemplateRole(ids, i) { // 获取模板角色列表
            let { data } = await getTemplateRole({ids})
            if (i == 'sendRole') {
                data = data.filter((item) => {
                    return item.id != 16
                })
            }
            this.roleData = data
        },
        async getRoleList(i) { // 获取模板角色列表
            let { data } = await getRoleList({pageNum: 1, pageSize: 9999, companyId: this.comId})
            if (i == 'sendRole') {
                data.records = data.records.filter((item) => {
                    return item.id != 16
                })
            }
            this.roleData = data.records
        },
        async commonAllRole () { // 角色列表
            let { data } = await commonAllRole({companyId: this.comId})
            this.roleData = data
        },
        deletePerson (row) { // 删除选中人员
            let checkedList = [...this.checkedList]
            let personData = [...this.personData]
            checkedList = checkedList.filter((item) => {
                return row.id != item.id
            })
            personData.map((item) => {
                if (row.id == item.id) {
                    item.checked = false
                }
            })
            this.checkedList = checkedList
            this.personData = personData
        },
        changePerson(val, row) { // 选人员变动
            let checkedList = [...this.checkedList]
            let personData = [...this.personData]
            let index = this.editIndex
            if (val) {
                if (index == 'sendRole') {
                    checkedList.push({id: row.id, name: row.nickName})
                } else if (index != 'sendRole' && !checkedList.length) {
                    checkedList.push({id: row.id, name: row.nickName})
                } else {
                    personData.map((item) => {
                        item.checked = false
                        checkedList.map((itemTwo) => {
                            if (item.id == itemTwo.id) {
                                item.checked = true
                            }
                        })
                    })
                    return this.$message.error("最多只能选择一位成员")
                }
            } else {
                checkedList = checkedList.filter((item) => {
                    return row.id != item.id
                })
            }
            this.checkedList = checkedList
        },
        departTreeFun (val) { //部门选择
            let params = {
                companyId: this.comId,
                roleId: val.id,
                pageNum: 1,
                pageSize: 999999
            }
            this.personList(params)
        },
        async personList(params) { // 获取人员
            let { data } = await personList(params)
            let checkedList = [...this.checkedList]
            if (data.records && data.records.length) {
                data.records.map((item) => {
                    item.checked = false
                    checkedList.map((itemTwo) => {
                        if (item.id == itemTwo.id) {
                            item.checked = true
                        }
                    })
                })
            }
            this.personData = data.records || []
        },
        seachFun (val) { // 搜索人员
            let index = this.editIndex
             let launchTaskForm = JSON.parse(JSON.stringify(this.launchTaskForm))
            let params = {
                companyId: this.comId,
                name: val,
                pageNum: 1,
                pageSize: 999999
            }
            if (index == 'sendRole') {
                params.roleIds  = launchTaskForm.sendRole
            } else if(index == 'chargeRole') {
                params.roleIds  = launchTaskForm.chargeRole
            } else {
                params.roleIds  = launchTaskForm.flowList[index].dealRole
            }
            this.personList(params)
        },
        personShow(ids, i) { // 选择人员
            if (ids) {
                this.getTemplateRole(ids, i)
            } else {
                this.getRoleList(i)
            }
            let noticeList
            if ( i == 'chargeRole' ) {
                noticeList = [...this.launchTaskForm.chargeList]
            } else if ( i == 'sendRole' ) {
                noticeList = [...this.launchTaskForm.sendList]
            } else {
                noticeList = [...this.launchTaskForm.flowList[i].noticeList]
            }
            this.editIndex = i
            this.personData = []
            this.checkedList = noticeList
            this.seachInfo = ''
            this.personVisible = true
        },
        changeTypeFun(val) { // 更改类型
            this.workOrderTemplate(val)
        },
        async workOrderTemplate(workOrderId) { // 获取工单模板
            let { data } = await workOrderTemplate({workOrderId})
            if (data && data.flowList && data.flowList.length) {
                data.flowList.map((item) => {
                    item.fileList = []
                    item.noticeList = []
                    if (item.dealId && item.dealName) {
                        let dealName = item.dealName.split(',')
                        let dealId = item.dealId.split(',')
                        dealName.map((itemTwo, indexTwo) => {
                            item.noticeList.push({ name: itemTwo, id: dealId[indexTwo] })
                        })
                    }
                    item.status = 0
                })
            }
            data.sendList = []
            if (data.sendId && data.sendName) {
                let sendName = data.sendName.split(',')
                let sendId = data.sendId.split(',')
                sendName.map((item, index) => {
                    data.sendList.push({name: item, id: sendId[index]})
                })
            }
            data.chargeList = []
            if (data.chargeId && data.chargeName) {
                let chargeName = data.chargeName.split(',')
                let chargeId = data.chargeId.split(',')
                chargeName.map((item, index) => {
                    data.chargeList.push({name: item, id: chargeId[index]})
                })
            }
            this.launchTaskForm = data
            if (this.$refs["launchTaskForm"]) this.$refs["launchTaskForm"].resetFields();
        },
        async workOrderType() { //类型下拉
            let { data } = await workOrderType({workTypeId: 2, companyId: this.comId, pageSize: 999999, pageNum: 1})
            this.taskTypeSelect = data.list
        },
        async deleteFlieItem (i, iTwo) {  //  删除文件
            let launchTaskForm = JSON.parse(JSON.stringify(this.launchTaskForm))
            let fileList = [...launchTaskForm.flowList[i].fileList]
            fileList = fileList.filter((item, index)=>{
                return iTwo != index
            })
            this.launchTaskForm.flowList[i].fileList = fileList
        },
        async deleteFlie (i) {  //  删除文件
            let fileList = [...this.fileList]
            fileList = fileList.filter((item, index)=>{
                return i != index
            })
            this.fileList = fileList
        },
        async uploadFileItem (e, index) { // 上传文件
            let launchTaskForm = JSON.parse(JSON.stringify(this.launchTaskForm))
            let file = e.target.files[0];
            let fileName = file.name;
            let format = file.name.split(".")[file.name.split(".").length - 1];
            if (file.size / 1024 > 1024 * 50) {
                e.target.value = "";
                return this.$message.error('文件大小不能超过50M')
            }
            if (!Config.fileType.PWEIType.includes(format)) {
                e.target.value = "";
                return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
            }
            this.loading = true
            let { data, code } = await upload({ file: file });
            this.loading = false
            if ( code == 200 ) {
                this.$message.success("上传成功")
                if (launchTaskForm.flowList) {
                    launchTaskForm.flowList[index].fileList.push({ filedName: fileName, filedUrl: data.filename, filedId: data.id, filedType: Config.fileType.imgType.includes(format) ? 'imgText' : 'file'})
                }
                this.launchTaskForm = launchTaskForm
            }
            e.target.value = ''
        },
        async uploadFile (e) { // 上传文件
            let fileList = [...this.fileList]
            let file = e.target.files[0];
            let fileName = file.name;
            let format = file.name.split(".")[file.name.split(".").length - 1];
            if (file.size / 1024 > 1024 * 50) {
                e.target.value = "";
                return this.$message.error('文件大小不能超过50M')
            }
            if (!Config.fileType.PWEIType.includes(format)) {
                e.target.value = "";
                return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
            }
            this.loading = true
            let { data, code } = await upload({ file: file });
            this.loading = false
            if ( code == 200 ) {
                this.$message.success("上传成功")
                fileList.push({ filedName: fileName, filedUrl: data.filename, filedId: data.id, filedType: Config.fileType.imgType.includes(format) ? 'imgText' : 'file'})
                this.fileList = fileList
            }
            e.target.value = ''
        },
    }
}
</script>
<style lang="less" scoped>
    .launch-container {
        text-align: left;
        height: 100%;
        display: flex;
        flex-direction: column;
        .form-overflow {
            flex: 1;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .footer-container {
            width: 100%;
            height: 88px;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            .btn-content {
                text-align: right;
                margin-right: 32px;
            }
        }
        .process-title {
            height: 32px;
            line-height: 32px;
            margin-left: 16px;
        }
        .common-uploadBnList-container {
            margin-left: 16px;
        }
        .process-textarea-container {
            width: 720px;
            border: 1px solid #ccc;
            border-radius: 4px;
            margin: 16px;
            .textarea-title {
                border-bottom: 1px solid #ccc;
                height: 40px;
                font-size: 14px;
                line-height: 40px;
                padding-left: 16px;
                background: #eaebea;
            }
            /deep/.el-textarea__inner {
                border: 0;
            }
        }
        .process-input-container {
            margin-left: 16px;
            width: 300px;
            /deep/.el-input-group__prepend {
                color: #333;
                background: #EBEBEB;
            }
            /deep/.el-input__inner {
                height: 40px;
                line-height: 40px;
            }
        }
        .timelineItem-dot {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 4px solid #eff6ff;
            background: #1890ff;
            color: #eff6ff;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
        }
        /deep/.el-timeline-item__tail {
            left: 11px;
        }
        .workOrder-process-container {
            padding: 44px 28px;
            margin: 16px 32px 32px;
            box-sizing: border-box;
            border-radius: 4px;
            border: 1px solid #d9d9d9;
        }
        .workOrder-title {
            font-size: 14px;
            color: #333;
            line-height: 1;
            padding: 24px 32px;
        }
        .demo-ruleForm {
            border-bottom: 1px solid #eee;
            padding: 32px 16px 16px;
            .taskForm-LeftRight {
                vertical-align: top;
                width: 34%;
                display: inline-block;
                &:nth-of-type(1) {
                    margin-right: 100px;
                }
            }
        }
        .launch-title {
            font-size: 24px;
            color: #101010;
            height: 88px;
            line-height: 56px;
            padding: 16px 32px;
            box-sizing: border-box;
        }
        .grey-line-1px {
            height: 1px;
            width: 120%;
            margin: 0 0 0 -10%;
            background: #eee;
        }
        .grey-line {
            height: 16px;
            width: 120%;
            margin: 0 0 0 -10%;
            background: #eee;
        }
    }
</style>